export class APP_ROUTES {
	public static root: string = '';
	public static slash: string = '/';
	public static previous: string = '../';
	public static new: string = 'new';

	//* auth layout */
	public static auth: string = 'auth';
	public static login: string = 'login';
	public static cpLogin: string = 'cplogin';
	public static signup: string = 'create-account';
	public static forgotPassword: string = 'forgot-password';
	public static changePassword: string = 'change-password';
	public static infoAccount: string = 'info-account';

	public static cp: string = 'cp';
	public static portal: string = 'portal';
	public static products: string = 'products';
	public static settings: string = 'settings';
	public static home: string = 'home';
	public static numberList: string = 'list-number';
	public static projectType: string = 'project-type';
	public static searchPlanningAndZoning: string = 'search-planning-and-zoning';
	public static searchPermits: string = 'search-permits';
	public static listNumber: string = 'list-number';
	public static contractorLicenses: string = 'contractor-licenses';
	public static pendingInspections: string = 'pending-inspections';
	public static contractorRegistrations: string = 'find-contractor-registrations';
	public static contractorDetails: string = 'contractor-details';
	public static projectDetails: string = 'project-details';
	public static projectTypeDetails: string = 'project-type-details';
	public static requestInspection: string = 'request-inspection';
	public static searchApplicationsResult: string = 'search-applications-result';
	public static searchPermitsResult: string = 'search-permits-result';
	public static applicationDetails: string = 'application-details';
	public static searchPlanningAndZoningResult: string = 'search-planning-and-zoning-result';
	public static projectTypeUseExisting: string = 'project-type-use-existing';
	public static applicationWizard: string = 'application-wizard';
	public static applicationDetailAddress: string = 'applicationdetail-address';
	public static applicationFees: string = 'application-fees';
	public static applicationDetailFileupload: string = 'applicationdetail-fileupload';
	public static applicationdetailContacts: string = 'applicationdetail-contacts';
	public static applicationListContacts: string = 'application-list-contacts';
	public static applicationDetailContractor: string = 'applicationdetail-contractor';
	public static pendingApplications: string = 'pending-applications';
	public static detailsApplications: string = 'details-applications';
	public static applicationComments: string = 'application-comments';
	public static processPayment: string = 'process-payment';
	public static processPaymentReturn: string = 'process-payment-return';
	public static applicationDetailQuestionnaire: string = 'applicationdetail-questionnaire';
	public static myProjects: string = 'my-projects';
	public static customReports: string = 'custom-reports';
	public static advancedReporting: string = 'advanced-reporting';
	public static request: string = 'request';
	public static requestSelectIssue: string = 'request-select-issue';
	public static requestContact: string = 'request-contact';
	public static mapLocation: string = 'map-location';
	public static myRequest: string = 'pending-solution-center-applications';
	public static internalMap: string = 'internal-map';
	public static projectTypeQuestionnaire: string = 'project-type-questionnaire';
	public static projectTypeFilesupload: string = 'project-type-filesupload';
	public static projectTypeApplicant: string = 'project-type-applicant';
	public static projectTypeReview: string = 'project-type-review';
	public static projectTypeContacts: string = 'project-type-contacts';
	public static searchSuggestAddresses: string = 'search-suggest-addresses';
	public static addresses: string = 'addresses';
	public static projectPublicComments: string = 'project-public-comments';
	public static cancelInspections: string = 'cancel-inspections';

	public static mgo: string = 'mgo';
	public static projectmanager: string = 'projectmanager';
}