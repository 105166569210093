import { environment } from "../../environments/environment";

export class API_Routes {
    public static apiBaseUrl: string = `${environment.baseApiUrl}/`;
    public static customerPortalApiUrl: string = `${environment.customerPortalApiUrl}/`;
    public static siteUrl: string = `${environment.siteUrl}/`;
    public static geoMyGovernmentonlineOrg: string = `${environment.geoMyGovernmentonlineOrg}/`;

    public static get cognitoUser(): string {
        return this.apiBaseUrl + "cognitouser/";
    }

    public static get prepoffline(): string {
        return this.apiBaseUrl + "jpv2/workorders/prepoffline/";
    }

    public static get createInspection(): string {
        return this.apiBaseUrl + "api/v3/jp/inspections/create-inspection/";
    }

    public static get getStates(): string {
        return this.customerPortalApiUrl + "helper/getstates/";
    }

    public static get suggestAddresses(): string {
        return this.customerPortalApiUrl + "helper/suggestaddresses/";
    }

    public static get getJurisdictions(): string {
        return this.customerPortalApiUrl + "helper/getjurisdictions/";
    }

    public static get getJurisdictionByID(): string {
        return this.apiBaseUrl + "cp/GetJurisdictionByID/";
    }

    public static get getJurisdictionUIConfiguration(): string {
        return this.apiBaseUrl + "cp/GetJurisdictionUIConfiguration/";
    }

    public static get jpv2Notification(): string {
        return this.apiBaseUrl + "jpv2/notifications/";
    }

    public static get getApplicationUID(): string {
        return this.apiBaseUrl + "cp/Applications/GetApplicationUID/";
    }

    public static get getApplicationUIDByCommentID(): string {
        return this.apiBaseUrl + "api/v3/cp/application/get-applicationuid-by-commentid/";
    }

    public static get getJurisdictionMenu(): string {
        return this.apiBaseUrl + "cp/GetJurisdictionMenu/";
    }

    public static get getJurisdictionCarousels(): string {
        return this.apiBaseUrl + "cp/GetJurisdictionCarousels/";
    }

    public static get sendContactEmail(): string {
        return this.customerPortalApiUrl + "helper/sendcontactemail/";
    }

    public static get getJurisdictionPaymentOptions(): string {
        return this.customerPortalApiUrl + "helper/getjurisdictionpaymentoptions/";
    }

    public static get getUserByToken(): string {
        return this.customerPortalApiUrl + "user/getuserbytoken/";
    }

    public static get updateUser(): string {
        return this.customerPortalApiUrl + "user/update/";
    }

    public static get startPhoneVerification(): string {
        return this.customerPortalApiUrl + "helper/startphonenumberverification/";
    }

    public static get createUserPhoneNumber(): string {
        return this.customerPortalApiUrl + "helper/createuserphonenumber/";
    }

    public static get getUserPhoneNumbers(): string {
        return this.customerPortalApiUrl + "helper/getuserphonenumbers/";
    }

    public static get contractors(): string {
        return this.apiBaseUrl + "cp/Contractors/all/";
    }

    public static get searchContractOrRegistrations(): string {
        return this.customerPortalApiUrl + "helper/searchcontractorregistrations/";
    }

    public static get addContractOrRegistration(): string {
        return this.customerPortalApiUrl + "helper/addcontractorregistration/";
    }

    public static get getByContractorId(): string {
        return this.apiBaseUrl + "api/v3/cp/Contractors/getbycontractorid/";
    }

    public static get getLicenseContractorId(): string {
        return this.apiBaseUrl + "api/v3/cp/Contractors/licenses/GETbycontractorid/";
    }

    public static get getFileByContractorId(): string {
        return this.apiBaseUrl + "cp/Contractors/files/GETbycontractorid/";
    }

    public static get getContactsByContractorId(): string {
        return this.apiBaseUrl + "cp/Contractors/contacts/GETbycontractoruid/";
    }

    public static get getCertificatesByContractorId(): string {
        return this.apiBaseUrl + "api/v3/contractorcertificates/getbycontractorid/";
    }

    public static get getContractorPayment(): string {
        return this.customerPortalApiUrl + "helper/getcontractorpayments/";
    }

    public static get getPermitByContractorId(): string {
        return this.apiBaseUrl + "cp/Contractors/permit/GETbycontractorid/";
    }

    public static get createUserProject(): string {
        return this.customerPortalApiUrl + "helper/createuserprojects/";
    }

    public static get getRenewalFees(): string {
        return this.apiBaseUrl + "cp/Contractors/licenses/getrenewalfees/";
    }

    public static get renewalPaymentAcknowledgements(): string {
        return this.apiBaseUrl + "cp/Contractors/jurisdiction/renewalpaymentacknowledgements/";
    }

    public static get getAutoAssessmentFees(): string {
        return this.apiBaseUrl + "cp/Contractors/jurisdiction/getautoassessmentfees/";
    }

    public static get getUnpaidFeesByContractorId(): string {
        return this.apiBaseUrl + "api/v3/cp/Contractors/fees/getunpaidfeesbycontractorid/";
    }

    public static get paymentOptions(): string {
        return this.apiBaseUrl + "cp/Contractors/jurisdiction/paymentoptions/";
    }

    public static get getAutoAssessmentPaymentFees(): string {
        return this.apiBaseUrl + "api/v3/cp/Contractors/fees/getautoassessmentpaymentfees/";
    }

    public static get getBycontractorId(): string {
        return this.apiBaseUrl + "api/v3/cp/Contractors/contacts/getbycontractorid/";
    }

    public static get contractorCertificateGetRenewalFee(): string {
        return this.apiBaseUrl + "cp/Contractors/certificate/getrenewalfees/";
    }

    public static get initiateContractorPayment(): string {
        return this.apiBaseUrl + "api/v3/payment-gateway/initiate-contractor-payment/";
    }

    public static get paymentProcessor(): string {
        return this.apiBaseUrl + "api/v3/cp/payment-processor/";
    }

    public static get inspections(): string {
        return this.apiBaseUrl + "cp/Inspections";
    }

    public static get projectPaymentReceipt(): string {
        return this.apiBaseUrl + "api/v3/cp/project-payment-receipt/";
    }

    public static get createWorkorderRequest(): string {
        return this.apiBaseUrl + "helper/createworkorderrequest/";
    }

    public static get initiateProjectPayment(): string {
        return this.apiBaseUrl + "api/v3/payment-gateway/initiate-project-payment";
    }

    public static get getProjectById(): string {
        return this.apiBaseUrl + "cp/Projects/ByID/";
    }

    public static get updateUserProjectDescription(): string {
        return this.apiBaseUrl + "cp/Projects/UpdateUserProjectDescription";
    }

    public static get getRequesTableInspections(): string {
        return this.customerPortalApiUrl + "helper/getrequestableinspections/";
    }

    public static get getProjectAddresses(): string {
        return this.customerPortalApiUrl + "helper/getprojectaddresses/";
    }

    public static get getProjectAutoAssessmentPaymentFees(): string {
        return this.customerPortalApiUrl + "helper/getprojectautoassessmentpaymentfees/";
    }

    public static get checkContractorRestrictionByApplicationType(): string {
        return this.customerPortalApiUrl + "helper/CheckContractorRestrictionByApplicationType/";
    }

    public static get checkJurisdictionInspectionControlDates(): string {
        return this.apiBaseUrl + "cp/InspectionSchedules/CheckJurisdictionInspectionControlDates";
    }

    public static get getProjectTypesByPermitTypes(): string {
        return this.customerPortalApiUrl + "helper/getprojecttypesbypermittypes/";
    }

    public static get GetApplicationStatuses(): string {
        return this.apiBaseUrl + "cp/Applications/GetApplicationStatuses";
    }

    public static get JurisdictionProjectStatuses(): string {
        return this.apiBaseUrl + "cp/Projects/JurisdictionProjectStatuses";
    }

    public static get projectByUserId(): string {
        return this.apiBaseUrl + "cp/Projects/ByUserID";
    }

    public static get getJurisdictionWorkTypes(): string {
        return this.apiBaseUrl + "cp/Projects/GetJurisdictionWorkTypes/";
    }

    public static get customReports(): string {
        return this.apiBaseUrl + "api/v3/cp/custom-reports/";
    }

    public static get getJurisdictionDesignations(): string {
        return this.apiBaseUrl + "cp/Projects/GetJurisdictionDesignations/";
    }

    public static get getApplicationTypesForDropDownList(): string {
        return this.apiBaseUrl + "cp/ApplicationTypes/GetApplicationTypesForDropDownList/";
    }

    public static get getApplicationTypes(): string {
        return this.apiBaseUrl + "cp/ApplicationTypes/GetApplicationTypes";
    }

    public static get getJurisdictionSites(): string {
        return this.apiBaseUrl + "cp/Projects/GetJurisdictionSites/";
    }

    public static get searchApplications(): string {
        return this.apiBaseUrl + "cp/Applications/SearchApplications";
    }

    public static get searchProjects(): string {
        return this.apiBaseUrl + "cp/Projects/SearchProjects";
    }

    public static get createuserProject(): string {
        return this.apiBaseUrl + "helper/createuserproject/";
    }

    public static get jurisdictionProjectTypes(): string {
        return this.apiBaseUrl + "cp/Projects/JurisdictionProjectTypes/";
    }

    public static get projectTypes(): string {
        return this.apiBaseUrl + "cp/Projects/ProjectTypes";
    }

    public static get createuserProjectCp(): string {
        return this.customerPortalApiUrl + "helper/createuserproject/";
    }

    public static get GetApplicationTypes(): string {
        return this.customerPortalApiUrl + "helper/getapplicationtypes/";
    }

    public static get searchPlanning(): string {
        return this.apiBaseUrl + "api/v3/cp/project/search-plannings";
    }

    public static get projectPayments(): string {
        return this.apiBaseUrl + "cp/ProjectPayments";
    }

    public static get getCustomerProjectFilesByProjectID(): string {
        return this.apiBaseUrl + "cp/ProjectFiles/GetCustomerProjectFilesByProjectID/";
    }

    public static get getProjectFileSignedUrl(): string {
        return this.apiBaseUrl + "api/v3/cp/ProjectFiles/get-project-file-signed-url/";
    }

    public static get filePreSignedUrl(): string {
        return this.apiBaseUrl + "cp/ProjectFiles/FilePreSignedUrl/";
    }

    public static get getProjectTypesByApplicationTypes(): string {
        return this.apiBaseUrl + "cp/getprojecttypesbyapplicationtypes";
    }

    public static get applicationTypeFileInstruction(): string {
        return this.apiBaseUrl + "cp/ApplicationTypes/ApplicationTypeFileInstruction/";
    }

    public static get searchApplyOnlineProjects(): string {
        return this.apiBaseUrl + "cp/Projects/searchapplyonlineprojects";
    }

    public static get getVerifyAddressForGis(): string {
        return this.apiBaseUrl + "api/v3/cp/application/get-verify-address-for-gis";
    }

    public static get updateProjectIDReferenceInEPermitApplication(): string {
        return this.apiBaseUrl + "cp/Applications/UpdateProjectIDReferenceInEPermitApplication";
    }

    public static get getApplication(): string {
        return this.customerPortalApiUrl + "epermit/getapplication/";
    }

    public static get getApplyOnlineAutoAssessmentApplicationFees(): string {
        return this.customerPortalApiUrl + "helper/getapplyonlineautoassessmentapplicationfees/";
    }

    public static get getApplyOnlineAutoAssessmentApplicationPaymentFees(): string {
        return this.customerPortalApiUrl + "helper/getapplyonlineautoassessmentapplicationpaymentfees/";
    }

    public static get checkContingentOnContractorLicensing(): string {
        return this.customerPortalApiUrl + "helper/checkContingentOnContractorLicensing/";
    }

    public static get getProjectFiles(): string {
        return this.customerPortalApiUrl + "helper/getprojectfiles/";
    }

    public static get getProjectContactsExtended(): string {
        return this.customerPortalApiUrl + "helper/getprojectcontactsextended/";
    }

    public static get sendToJurisdiction(): string {
        return this.customerPortalApiUrl + "epermit/SendToJurisdiction/";
    }

    public static get epermitUpdate(): string {
        return this.customerPortalApiUrl + "epermit/update/";
    }

    public static get epermitDelete(): string {
        return this.customerPortalApiUrl + "epermit/delete/";
    }

    public static get deleteUserPermit(): string {
        return this.customerPortalApiUrl + "helper/deleteuserpermit/";
    }

    public static get searchContractors(): string {
        return this.customerPortalApiUrl + "helper/searchcontractors/";
    }

    public static get getPublicComments(): string {
        return this.customerPortalApiUrl + "projects/getpubliccomments/";
    }

    public static get epermitCreate(): string {
        return this.customerPortalApiUrl + "epermit/create/";
    }

    public static get getApplicationFormModules(): string {
        return this.customerPortalApiUrl + "helper/getapplicationformmodules/";
    }

    public static get getApplications(): string {
        return this.customerPortalApiUrl + "helper/getapplications/";
    }

    public static get getApplicationFormModulesByApplicationId(): string {
        return this.customerPortalApiUrl + "helper/getapplicationformmodulesbyapplicationid/";
    }

    public static get getApplyOnlineAcknowledgements(): string {
        return this.customerPortalApiUrl + "helper/getapplyonlineacknowledgements/";
    }

    public static get getApplicationcontacttypes(): string {
        return this.customerPortalApiUrl + "helper/getapplicationcontacttypes/";
    }

    public static get getProjectTypesByMobile(): string {
        return this.customerPortalApiUrl + "helper/getprojecttypesbymobile/";
    }

    public static get getLatestProjects(): string {
        return this.customerPortalApiUrl + "helper/getlatestprojects/";
    }

    public static get getProjectPlanReviewLetters(): string {
        return this.customerPortalApiUrl + "helper/getprojectplanreviewletters/";
    }

    public static get getProjectForms(): string {
        return this.customerPortalApiUrl + "helper/getprojectforms/";
    }

    public static get getProjectTypeFees(): string {
        return this.customerPortalApiUrl + "helper/getprojecttypefees/";
    }

    public static get getTypesByProjectId(): string {
        return this.customerPortalApiUrl + "helper/gettypesbyprojectid/";
    }

    public static get getSolutionCenterApplications(): string {
        return this.customerPortalApiUrl + "helper/getsolutioncenterapplications/";
    }

    public static get getFullStates(): string {
        return this.customerPortalApiUrl + "helper/getfullstates/";
    }

    public static get contact(): string {
        return this.apiBaseUrl + "contactform";
    }

    public static get applicationContact(): string {
        return this.apiBaseUrl + "cp/ApplicationContact/";
    }

    public static get getApplicationContactTypes(): string {
        return this.apiBaseUrl + "cp/ApplicationContact/GetApplicationContactTypes/";
    }

    public static get suggestContractors(): string {
        return this.apiBaseUrl + "api/v3/cp/Contractors/suggest-contractors";
    }

    public static get projectRequirementsByProjectId(): string {
        return this.apiBaseUrl + "cp/ProjectRequirements/byProjectID/";
    }

    public static get getTypeFees(): string {
        return this.apiBaseUrl + "cp/Applications/GetTypeFees";
    }

    public static get getTypeCalculationFormula(): string {
        return this.apiBaseUrl + "cp/Applications/GetTypeCalculationFormula";
    }

    public static get applicationAddresses(): string {
        return this.apiBaseUrl + "cp/ApplicationAddresses/";
    }

    public static get addApplicationComment(): string {
        return this.apiBaseUrl + "cp/Applications/AddApplicationComment";
    }

    public static get addUpdateApplicationTypeFees(): string {
        return this.apiBaseUrl + "cp/Applications/AddUpdateApplicationTypeFees";
    }

    public static get removeApplicationFile(): string {
        return this.apiBaseUrl + "cp/Applications/RemoveApplicationFile/";
    }

    public static get paymentGatewayInitiatePayment(): string {
        return this.apiBaseUrl + "api/v3/payment-gateway/initiate-payment";
    }

    public static get ProjectFilesCreate(): string {
        return this.apiBaseUrl + "api/v3/cp/ProjectFiles/Create/";
    }

    public static get savePublicComment(): string {
        return this.apiBaseUrl + "cp/ProjectComments/savepubliccomment";
    }

    public static get projectById(): string {
        return this.apiBaseUrl + "cp/Projects/ByUID/";
    }

    public static get projects(): string {
        return this.apiBaseUrl + "cp/Projects/";
    }

    public static get authorizeNetprocessPayment(): string {
        return this.apiBaseUrl + "api/v3/authorize-net/process-payment";
    }

    public static get bridgeNetprocessPayment(): string {
        return this.apiBaseUrl + "api/v3/bridge-pay/process-payment";
    }

    public static get globalNetprocessPayment(): string {
        return this.apiBaseUrl + "api/v3/global-payments/process-payment";
    }

    public static get globalNetprocessPaymentAch(): string {
        return this.apiBaseUrl + "api/v3/global-payments/process-payment-ach";
    }

    public static get nmiprocessPayment(): string {
        return this.apiBaseUrl + "api/v3/nmi-payments/process-payment";
    }

    public static get kiowareProcessPayment(): string {
        return this.apiBaseUrl + "api/v3/kioware/process-payment";
    }
}