import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_Routes } from "../@routes"
import { CookieService } from 'ngx-cookie';

@Injectable()

export class ApiCacheInterceptor implements HttpInterceptor {
    private cache = new Map<string, HttpResponse<any>>();
    private endpointsToCache = new Set([
        `${API_Routes.getStates}${this.cookieService.get('MGUserToken') || '-'}`,
        `${API_Routes.getJurisdictions}${this.cookieService.get('MGUserToken') || '-'}`,
    ])

    constructor(private cookieService: CookieService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Remove the API base url from the request URL
        const url = request.url.replace(API_Routes.customerPortalApiUrl, '');
        // check if the request endpoint is in the set of the endpoints to cache
        if (this.endpointsToCache.has(request.url)) {
            // Try to retrieve the cached response for the request
            const cachedResponse = this.cache.get(request.url);
            // if the response is found in the cache, return it as an obervable  of HttpResponse
            console.log(cachedResponse)
            if (cachedResponse) {
                return of(cachedResponse);
            }
            // if the response is not found in the cache, send the request and cache the response
            return next.handle(request).pipe(
                tap((response) => {
                    if (response instanceof HttpResponse) {
                        this.cache.set(request.url, response);
                    }
                })
            )
        }
        return next.handle(request);
    }
}