import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
// import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthInterceptor, ErrorInterceptor, ApiCacheInterceptor } from "./@interceptors";
import { CoreModule } from './@core/core.module';
import { UserService } from './@core/mock/users.service';
import { SignalrService } from "./@core/http/signalr.service";
import { MainHubService } from "./@core/utils/services/main-hub.service";

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		CookieModule.withOptions(),
		CoreModule.forRoot(),
		AppRoutingModule,
		// ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
	],
	providers: [
		UserService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiCacheInterceptor, multi: true },
		//  { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
		MainHubService,
		SignalrService,
		{
			provide: APP_INITIALIZER,
			useFactory: (signalrService: SignalrService) => () => signalrService.initiateSignalrConnection(),
			deps: [SignalrService],
			multi: true,
		}
	],
	bootstrap: [AppComponent]
})

export class AppModule { }