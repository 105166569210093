import { Injectable } from '@angular/core';

@Injectable()

export class ProjectService {

	globalSaveVisible: boolean = false;
	globalSaveMarked: boolean = false;

	constructor() { }

}
